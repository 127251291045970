/*
// .typography
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';

.CookieConsent{
max-width: none;
}
      .cookieContent{
       display:flex;
       gap:2rem;

      }
      .politicaLink{
        color: white;
        text-decoration:underline white;
        font-weight: bolder
      }
    
// COOKIE CONFIG STYLES //
.containerScrool{
height: 500px;
padding: .5rem;
overflow:scroll;
overflow-x: hidden;
}
.logo_modal_ica{
 max-height: 40px;

}



.headCookieInfo{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookieTitle{
font-size: 16px;
margin: .5rem 0;
}
.cookieSubTitle{
  font-size: 14px;

}
.cookieText{
font-size: 12px;
}
.cookieButton {
  background-color: #004fbd;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 12px;
  cursor: pointer;

  .cookieButton:hover {
    background-color: #0069d9;
  }
}
.buttonSelfPlaced{
flex: 1;
}    

      //COOKIE MODAL NUEVO , HAY QUE MODIFICARLO
        .cookie-modal{
          &-overlay {
            // background-color: rgba(0, 0, 0, 0.5);
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
            backdrop-filter: blur(2px);
          }
          &-buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            padding: 0 1rem 0 0;
            gap: .5rem;
            button {
              background-color: #004fbd;
              color: #ffffff;
              border: none;
              border-radius: 5px;
              padding: 0.5rem 1rem;
              font-size: 12px;
              cursor: pointer;
              &:hover {
                background-color: #024892;
              }
            }
          }
          &-content {
            position: relative;
            // background-color: #fff;
            background-color:rgba(240, 240, 240, 0.945);
            box-shadow: 4px 4px rgba(178,192,230,.19);
            max-width: 700px;
            width: 90%;
            max-height: 600px;
            padding: 20px;
            border-radius: 5px;
            .modal-close {
              position: absolute;
              top: 10px;
              right: 10px;
              font-size: 1.5rem;
              cursor: pointer;
            }
          }
          &-settings{
            overflow: auto;
            max-height: 450px;
          }
          &-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
           &-head{
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          }
          .cookie-modal-head > div{
            scale: .7;
          }
          .cookie-modal-close{
            width: 30px;
            height: 30px;
            background-color: transparent;
          border-radius: 3px;
            color: #004fbd;  
            border: 2px solid #004fbd;
              
          }
          .cookie-modal-close:hover{
            background-color: #004fbd;
            color: #ffff;  
          }
          .cookie-modal-close-container{
            display: flex;
            padding: 5px;
            justify-content: space-between;
          }
          .modalName{
            margin: .5rem 0;
          }
          .showMore{
            background-color: #004fbd;
            color: #ffff;
            padding: .1rem .4rem;
            margin-right: 0.1rem;
            cursor: pointer;
          }
          .showMore:hover{
            background-color: transparent;
            color: #004fbd;
          }
          .cookieText{
            transition-delay: 1000ms;
          }
          .cookie-modal-content{
            margin: 0 auto;
          }
          @media (max-width: 767px) {
            .cookie-modal-button button {
              font-size: 10px; /* Ajusta el tamaño de la fuente para dispositivos móviles */
              /* Otros estilos específicos para dispositivos móviles si es necesario */
            }
          }