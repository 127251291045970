.slidess {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: inherit;
    z-index: 20;
    /* Se muestra un color de fondo mientras se está cargando la imagen de fondo o si hay problemas para cargarla */
    background-color: #66999;
}
.slidess-background{
    width: 100%;
  object-fit: fill;
  height: 400px;
}
.background-container{
    position: absolute;
    width: 100%; /* Ajusta el ancho según tus necesidades */
    height: 400px; /* Ajusta la altura según tus necesidades */
    /* background-image: url('${process.env.REACT_APP_API_URL}assets/${item?.background}'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* Otros estilos adicionales si es necesario */
  
    /* Si quieres que el contenido se superponga sobre el fondo */
    position: relative;
}
.carousel-container{
display: flex;
justify-content: space-around;
align-items: center;
flex-wrap: wrap;
}
.carousel-container {
    display: flex;
}
.imagen-title-carousel{
    height: 100px;
    }

.text-container {
    gap: 5px;
    max-height: 380px;
    max-width: 600px;
}

.imagen-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.imagen-container {
    width: 400px;
    height: 400px;
    overflow: hidden;
    position: relative;
}



.code-carousel{
padding-bottom: 20px;
}
.imagen-body-carousel{
padding-bottom: 20px;

}
.button_carousel{
border: none;
background-color: #016CBE;
padding: .65rem;
color: #ffffff;
font-size: 20px;
border-radius: 10%;
cursor: pointer;
}

.button_carousel:hover{
    background-color: #3D464D;
}
.imagen-container.active img {
    animation: floatAnimation 8s ease-in forwards;
    }

.button_carousel.active{
    animation: btnAnimation 8s ease-in forwards;

}
@keyframes floatAnimation {
    0%{
        transform: translateX(0);
        opacity: 0;
    }
    15% {
        transform: translateX(-80px);
        opacity: 1;
    }
    30% {
        transform: translateX(-80px);
    }
    50% {
        transform: translateX(-80px);

    }
    75% {
        transform: translateX(-80px);
    }
    100% {
        transform: translateX(0px);
        animation-delay: 1s;
    }
}

@keyframes btnAnimation {
    0%{
        transform: translateY(0);
        opacity: 0;
    }
    30% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(-20px);
        opacity: 1;

    }
    75% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
        animation-delay: 2s;

    }
}

/* Estilos generales para .slidess y otras clases aquí */

/* Consulta de medios para dispositivos móviles */
@media (max-width: 767px) {
    .imagen-container.active img,
    .button_carousel.active {
      animation: none; /* Desactiva las animaciones en dispositivos móviles */
    }
    .imagen-title-carousel {
        height: 60px;
        font-size: 16px;
      }
    
      .text-container h1{
        font-size: 20px;
      }
      .text-container h4{
        font-size: 14px;
      }
      .button_carousel{
        font-size: 14px;

      } 
      .imagen-container {
        width: 300px;
        height: 300px; 
      }

    }