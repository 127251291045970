/*
// .site
*/
@import '../variables';


.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
}
.site__body {
    margin-top: 162px;
    flex-grow: 1;
    background-color:#faf8f8;
}

@media (max-width: 968px) {
        .site__body {
        margin-top: 0; /* Establece el margen superior a 0 en pantallas más pequeñas */
    }
}


.site__footer {
    flex-shrink: 0;
}
.container{
    max-width: 1240px;   
   }